import React from 'react'
import { Grid, Card, CardContent, Container, Button } from '@material-ui/core'
import Nav from '../components/nav'
import Image from '../components/Image'

export default () => (
  <Nav>
    <Container fixed maxWidth="sm">
      <Grid container>
        <Grid item xs className="py-24">
          <Card>
            <CardContent>
              <div className="text-center font-bold text-2xl mb-6">如何下载iOS版</div>
              <div className="text-sm">第一步：在App Store中下载并安装Testflight</div>
              <Image fileName="testflight.jpeg" className="my-3" alt="" />
              <div className="text-sm">第二步：请用手机扫描二维码或点击链接安装企业通</div>
              <div className="text-center">
                <Button color="primary" variant="contained" onClick={() => window.open('https://testflight.apple.com/join/tw7QHC2c')} className="my-3">
                  立即下载
                </Button>
                <div>
                  <Image fileName="download.png" alt="" className="w-1/2 m-auto" />
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  </Nav>
)
